* {
  box-sizing: border-box;
}

body {
  background-color: #e9e4e6;
}

div {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

.container {
  max-width: 1280px;
  width: 90%;
  margin: auto;
  margin-top: 4em;
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.2);
  border-radius: 0.5em;
}

.logo {
  max-width: 30%;
  margin-left: -10%;
}

hr {
  border-top: 3px solid #333;
  border-bottom: 0;
  margin: 40px auto;
  width: calc(100% - 16em);
}

h1 {
  margin-top: 0;
  color: #0242ae
}

h2 {
  color: #0242ae
}

.g-form {
  background-color: white;
  width: 100%;
  padding: 1em 0;
  border-radius: 0.5em 0.5em 0 0;
}

.formrow {
  width: 100%;
  padding: 0 8em;
  
}

.rightaligned {
  text-align: right;
}

.fullwidth {
  width: 75%; 
  margin: 0.5em 0 0.5em 1em;
  border-radius: 0;
  border: 1px solid lightgray;
  padding: 0.75em;
  font-size: 1em;
}

.radioGroup {
  display: inline-flex;
  margin: 0.5em 0 0.5em 1em;
  justify-content: space-evenly;
  width: 75%;
}

.textarea-comment {
  width: 75%;
  margin: 0.5em 0 0.5em 1em;
  border: 1px solid lightgray;
  padding: 0.75em;
  font-size: 1em;
}

.upload-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-upload {
  border: none;
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}

.input-submit {
  background-color: transparent;
  border: 1px solid #0242ae;

  border-radius: 0 0 0.5em 0.5em;
  text-align: center;
  width: 100%;
  padding: 2em;
  font-size: 1em;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.input-submit.error {
  background-color: palevioletred;
  border: 1px solid palevioletred;
}

.submit-wrapper {
  position: relative;
  background-color: transparent;
  border-radius: 0 0 0.5em 0.5em;
  cursor: pointer;
}

.progressbar {
  position: absolute;
  background-color: #0242ae;
  height: 100%;
  z-index: -1;
  border-radius: 0 0 0.5em 0.5em;
  cursor: pointer;
}

.file-input {
  width: calc(75% - 3em);
}

.formrow.witherror {
  background-color: palevioletred;
}

.err_desc {
  text-align: center;
  padding: 1em;
}

.sidebutton {
  border:0;
  font-size: 3em;
  position: relative;
  bottom: -0.19em;
  color: #0242ae;
  cursor: pointer;
  display: inline-block;
  width: 38px;
  text-align: center;
}

.return {
  width: 100%;
  background-color: #0242ae;
  color: white;
  text-align: center;
  padding: 2em;
  font-weight: bold;
  margin-bottom: -3em;
  margin-top: 2em;
  border-radius: 0 0 0.5em 0.5em;
  cursor: pointer;
}

@media(max-width: 1024px) {
  .input-name {
   width: 100%;
  }

  .label-upload {
      width: 47%;
  }

}